.mainDiv {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

input {
  /* border: black; */
  border-radius: 16px;
  border-width: 4px;
  /* border-color: aquamarine; */
  /* margin-inline: 98px; */
  padding: 5px;
  border-style: solid;
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
